import * as React from 'react';
import Layout from '../Components/Layout/Layout';
import WhatWeDo from '../Components/WhatWedo/WhatWeDo';
import SEO from '../Components/seo';
// markup
const WhatWeDoPage = () => (
  <>
    <SEO title="What We Do" />
    <Layout page="What we do">
      <main id="main">
        <WhatWeDo />
      </main>
    </Layout>
  </>

);

export default WhatWeDoPage;
